<template>
    <div class="page1">
        <EForm :formColumns="formColumns" :rowSize="4" :optionsBtn="true" :actionBtn="false" :formData="searchForm" ref="form" @onSearch="getData" :searchFlag="true" :exportShow="false">
        </EForm>
        <EButton type="primary" @click="dialogShow" size="small">增加</EButton>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="false"
        >
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="editDialog(scope.row)"
                        >
                            修改
                        </EButton>
                        <EButton type="text" @click="remove(scope.row.placeConfigProjectId)"
                        >
                            删除
                        </EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>

        <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="cancelDialog"
                 width="40%"
                 @handleClick="saveData"
        :disabled="saveDisabled"
        @open="changeInfo(1)"
        >
            <el-form ref="form" :model="dialogForm" label-position="right" label-width="100px"
                     :rules="formRules">
                <el-form-item label='位置名称' prop="placeId">
                    <el-select v-model="dialogForm.placeId" placeholder="请选择">
                        <el-option
                                v-for="item in formColumns[0].options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>

                </el-form-item>
                <el-form-item label='收费项目' prop="takeReturnProjectIds">
                    <el-checkbox-group v-model="dialogForm.takeReturnProjectIds">
                        <el-checkbox :label="item.takeReturnProjectId" v-for="(item,index) in options" :key="index">
                            {{item.takeReturnProjectName}}
                        </el-checkbox>
                    </el-checkbox-group>

                </el-form-item>
            </el-form>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import ETree from '@/components/ETree'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";
  export default {
    name: 'Login',
    mixins:[otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '区域',
            prop: 'areaName'
          },
          {
            label: '位置',
            prop: 'placeName'
          },
          {
            label: '收费项目',
            prop: 'projectCn'
          },
          {
            label: '新建时间',
            prop: 'createTime',
          },
          {
            label: '新建人',
            prop: 'createUserCn'
          },
          {
            label: '修改时间',
            prop: 'updateTime'
          },
          {
            label: '修改人',
            prop: 'updateUserCn'
          },
        ],
        tableData: [],
        formColumns: [
          {
            title: '位置',
            type: 'select',
            property: 'placeId',
            show: true,
            options: []
          },
        ],
        searchForm: {
          placeId:''
        },
        count: null,

        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        formRules: {
          takeReturnProjectIds: vxRule(true, '', "change", "收费项目不能为空"),
          placeId: vxRule(true, '', "change", "位置名称不能为空"),
        },
        dialogForm: {
          takeReturnProjectIds: [],//收费项目
          placeId: '',
          placeConfigProjectId:''
        },
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        options: [],
        areaName:'111',
        status:null,
        title:'增加'
      }
    },
    watch: {},
    created() {
      this.getData()
      this.changeInfo(null)
    },
    components: {ETable, EDialog, EButton, EForm, ETree},
    beforeMount() {

    },
    methods: {
      changeInfo(status){
        this.status=status
        this.getOptionData()
        this.getPlaceLabel()
      },
      //获取位置下拉
      async getPlaceLabel () {
        let res = await Http.getPlaceLabel({
          status:this.status
        })
        if (res.code == 200) {
          this.formColumns[0].options = res.data
        }
      },

      async cmareaconfigprojectRemove(ids){
        let res = await Http.cmareaconfigprojectRemove({ids})
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getData()
        }
      },
      remove(data){
        this.$messageBox.confirm('确定删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          this.cmareaconfigprojectRemove(data)
        }).catch(res => {

        });
      },
      editDialog(data){
        this.title='修改'
        this.dialogForm={
          placeConfigProjectId:data.placeConfigProjectId,
          takeReturnProjectIds:data.takeReturnProjectIds.split(','),
          placeId:data.placeId
        }
        if(this.dialogForm.takeReturnProjectIds.length>0){
          this.dialogForm.takeReturnProjectIds.forEach((item,index)=>{
            this.dialogForm.takeReturnProjectIds[index]=Number(item)
          })
        }
        this.areaName=data.areaName
        this.dialogVisible=true
        this.title='修改'
      },
      async cmareaconfigprojectSubmit() {
        this.setDisabled(true)
        let {
          placeConfigProjectId,
          takeReturnProjectIds,
          placeId
        }=this.dialogForm
        let res = await Http.cmareaconfigprojectSubmit({
          takeReturnProjectIds:takeReturnProjectIds.toString(),
          placeId,
          placeConfigProjectId
        })
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.setDisabled(false)
          this.cancelDialog()
          this.getData()
        }
      },
      dialogShow() {
        this.dialogVisible = true
      },
      onSearch() {
      },
      cancelDialog() {
        this.dialogVisible = false
        this.dialogForm = {
          takeReturnProjectIds: [],
          placeId: '',
          placeConfigProjectId:''
        }
        this.title='增加'
        this.changeInfo(null)
      },
      showDialog() {
        this.dialogVisible = true
      },
      saveData() {
        this.$refs['form'].validate((valid, object) => {
          if (valid) {
            this.cmareaconfigprojectSubmit()
          } else {
            return false;
          }
        });
      },
      async getData() {
        let res = await Http.getAreaConfigProjectList(this.searchForm)
        if (res.code == 200) {
          this.tableData = res.data
        }
      },
      async getOptionData() {
        let res = await Http.getTakereTurnList({
          status:this.status
        })
        if (res.code == 200) {
          this.options = res.data
        }
      }
    }
  }
</script>

<style lang="scss">

</style>
